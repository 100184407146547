import { jsPDF } from "jspdf";

import AptosNormal from "../../../utils/Customfonts/Aptos.ttf";
import AptosBold from "../../../utils/Customfonts/Aptos-Bold.ttf";

import autoTable from "jspdf-autotable";
import {
  customFontFamily,
  footerConfig,
  headerConfig,
  InstrumentDetails,
  loadAndAddFont,
  NameAndAddressDetails,
  srfDetailsData,
  SRFSBorderLayout,
  tableConfiguration,
  totalHeaderHeightWithCalibrationLableorNot,
} from "./srfsConfig";
import {
  printSRFSConfiguration,
  printSRFSConfigData,
  layoutConfig,
} from "./SRFSConstant";
import {
  drawDynamicFooter,
  drawDynamicHeader,
  drawLetterFooterImg,
  drawLetterHeadHeaderImg,
} from "./headerAndfooter";
import { drawBox, drawfirst2ColumnRowspan, drawForPTCCPLUseOnlyTable, drawText } from "./commonTable";
const Sign1 = "/images/sign1.png";


const drawPageHeader = () => {
  drawLetterHeadHeaderImg();
  drawDynamicHeader();
};

const drawPageFooter = () => {
  drawDynamicFooter();
  drawLetterFooterImg();
};

const initPdfDoc = async () => {
  printSRFSConfiguration.pdfDoc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });

  await Promise.all([
    loadAndAddFont(
      printSRFSConfiguration.pdfDoc,
      AptosNormal,
      "Aptos",
      customFontFamily?.normalFont,
      customFontFamily?.normal
    ),
    loadAndAddFont(
      printSRFSConfiguration.pdfDoc,
      AptosBold,
      "Aptos-Bold",
      customFontFamily?.boldFont,
      customFontFamily?.bold
    ),
  ]);

  // Set default font
  printSRFSConfiguration.pdfDoc.setFont(customFontFamily?.normalFont);
  printSRFSConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);

  commonPageContet();

  layoutConfig.yPositionGlobal =
    totalHeaderHeightWithCalibrationLableorNot.total;
};
const commonPageContet = () => {
  // add page header
  drawPageHeader();
  // add page footer
  drawPageFooter();
  // draw border
  printSRFSConfiguration.pdfDoc.rect(
    SRFSBorderLayout.x,
    SRFSBorderLayout.y,
    SRFSBorderLayout.width,
    SRFSBorderLayout.height
  );
};
const addNewPage = async () => {
  printSRFSConfiguration.pdfDoc.addPage();
  commonPageContet();
};

const initPage = () => {
  commonPageContet();
};

const setPageSection = async (addNewPages = false) => {
  if (
    layoutConfig.yPositionGlobal >
      printSRFSConfiguration.pageHeight -
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight -
        100 ||
    addNewPages
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total;
  }
};

const instrumentListTable = async (pushArray) => {
  await setPageSection();
 
  const panels = InstrumentDetails(pushArray);

  let tableRows = [];

  if (printSRFSConfigData.letterHeadAndElectronicAndDraftConfig?.asPerAttach) {
    const instrumentDetailsRow = [
      {
        content: "INSTRUMENT DETAILS AS PER ATTACHED LIST",
        colSpan: panels.keys.length,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
        },
      },
    ];
    tableRows.push(instrumentDetailsRow);
  } else {
    const instrumentDetailsRow = [
      {
        content: "INSTRUMENT DETAILS",
        colSpan: panels.keys.length,
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
        },
      },
    ];
    tableRows.push(instrumentDetailsRow);
    const headerRow = panels.keys.map((key) => ({
      content: key,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
      },
    }));
    tableRows.push(headerRow);
    panels.values.forEach((row) => {
      const dataRow = row.map((value) => ({
        content: value,
      }));
      tableRows.push(dataRow);
    });
  }

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal - panels.spacebetweenHeadingandTable,
    margin: {
      top: totalHeaderHeightWithCalibrationLableorNot.total,
      right: printSRFSConfiguration.margin,
      left: printSRFSConfiguration.margin,
      bottom:
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight +
        footerConfig.footerandbodygap,
    },
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: panels.tableTextfontSize || printSRFSConfiguration.fontSizes.h2,
      halign: panels.tableValueAlign || "left",
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      valign: "middle"  
    },
    didDrawPage: (data) => {
      if (data.pageNumber > 1) {
        initPage();
      }
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

const srfDetailsTable = async (srfDetails) => {
  const data1 = NameAndAddressDetails(srfDetails);
  drawfirst2ColumnRowspan(data1);
  const data = srfDetailsData(srfDetails);
  drawBox(data);
};

const csrfFormTable = async (csrfFormProps) => {
  // Array to store all rows
  const tableRows = [];

  // Iterate through CSRFformState and map data
  csrfFormProps?.CSRFformState?.forEach((item, index) => {
    // First Column: Question
    let questionContent = `${index + 1}. ${item?.question}`;
    if (item?.hasPoints) {
      const points = item?.points
        ?.map((point) => `${point}`)
        .join("\n"); // Join points with line breaks
      questionContent = `${questionContent}\n${points}`;
    }

    const questionCell = {
      content: questionContent,
      styles: {
        halign: "left",
      },
    };

    // Second Column: Options and Details
    const optionsAndDetails = [
      // Checkbox Options
      item?.options
        ?.map((option) => {
          const isChecked = item?.answer === option ? "☑" : "☐";
          return `${isChecked} ${option}`;
        })
        .join("  ") || "",
      // Details
      item?.hasDetails ? ` ${item?.details}` : "",
      // Additional Options
      item?.hasoptions
        ? item?.options1
            ?.map((opt) => {
              const isChecked = item?.details === opt ? "☑" : "☐";
              return `${isChecked} ${opt}`;
            })
            .join("  ") || ""
        : "",
      // Certificate Information
      item?.hasPoints
        ? `Certificate to be issued in the name of: ${
            csrfFormProps?.quest15?.details?.split(",")[1]?.trim() || ""
          }`
        : "",
    ]
      .filter(Boolean) // Remove empty strings
      .join("\n"); // Combine with newlines

    const optionsCell = {
      content: optionsAndDetails,
      styles: {
        halign: "left",
      },
    };

    // Add row to table
    tableRows.push([questionCell, optionsCell]);
  });

   // Add the new row with the signature
   tableRows.push([
    { content: "11. Reviewed & accepted as per standard requirements (By TM)", styles: { halign: "left" } },
    { 
      content: "", // Leave content empty as we'll add the image manually
      styles: { halign: "center" },
    }
  ]);
  tableRows.push([
    { content: "Customer Signature:", styles: { halign: "left" } },
    { 
      content: "Name:", // Leave content empty as we'll add the image manually
      styles: { halign: "center" },
    }
  ]);
  tableRows.push([
    { content: "Designation:", styles: { halign: "left" } },
    { 
      content: "Date:", // Leave content empty as we'll add the image manually
      styles: { halign: "center" },
    }
  ]);

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [40, 60];
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Generate table
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: {
      top: totalHeaderHeightWithCalibrationLableorNot.total,
      right: printSRFSConfiguration.margin,
      left: printSRFSConfiguration.margin,
      bottom:
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight +
        footerConfig.footerandbodygap,
    },
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h2,
      halign: "left",
      font: customFontFamily?.normalFont,
      cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      },
    bodyStyles: {
      lineWidth: 0,
    },
    didDrawCell: (data) => {
      // ✅ Add signature image to the specific cell
      if (data.row.index === tableRows.length - 3 && data.column.index === 1) {
        const imgWidth = 50; // Adjust image width
        const imgHeight = 20; // Adjust image height
        const xPos = data.cell.x + 5;
        const yPos = data.cell.y + (data.cell.height - imgHeight) / 2;

        printSRFSConfiguration.pdfDoc.addImage(Sign1, "PNG", xPos, yPos, imgWidth, imgHeight);
      }
    },
    didDrawPage: (data) => {
      if (data.pageNumber > 1) {
        initPage();
      }
    },
  });

  layoutConfig.yPositionGlobal =
  printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const generatePDF = async ({
  pushArray,
  letterHeadAndElectronicAndDraft,
  srfDetails,
  setDownlaodPdfLoading,
  IsCSRF,
  csrfFormProps,
}) => {
  try {
    setDownlaodPdfLoading(true);

    printSRFSConfigData.letterHeadAndElectronicAndDraftConfig =
      letterHeadAndElectronicAndDraft;

    printSRFSConfigData.IsCSRF = IsCSRF;
    printSRFSConfigData.SRFSDetailsConfig = srfDetails;

    await initPdfDoc();

    drawText(
      IsCSRF ? "SERVICE REQUEST FORM" : "SERVICE REQUEST FORM",
      printSRFSConfiguration.pageWidth - 85,
      totalHeaderHeightWithCalibrationLableorNot.total + 10,
      "center",
      13
    );
    drawText(
      "Format No: PTCCPL/7.1/FF/01",
      printSRFSConfiguration.pageWidth - 135,
      totalHeaderHeightWithCalibrationLableorNot.total + 10,
      "left",
      8,
      0,
      0,
      "normalfont"
    );

    await srfDetailsTable(srfDetails);
    await instrumentListTable(pushArray);
    if (IsCSRF) {
      await csrfFormTable(csrfFormProps);
    }

    await drawForPTCCPLUseOnlyTable(initPage);

    if (letterHeadAndElectronicAndDraft.letterHead) {
      drawText(
        "This SRF is electronically signed and does not required physical signature.",
        printSRFSConfiguration.pageWidth - 85,
        printSRFSConfiguration.pageHeight -
        footerConfig.footerimgHeight,
        "center",
        printSRFSConfiguration.fontSizes.h3,
        0,
        0,
        "normalfont"
      );
    }

    const totalPages = printSRFSConfiguration.pdfDoc.getNumberOfPages();
    for (let page = 1; page <= totalPages; page++) {
      printSRFSConfiguration.pdfDoc.setPage(page);
      let content = `Page No : ${String(page).padStart(2, "0")} of ${String(
        totalPages
      ).padStart(2, "0")}`;

      drawText(
        content,
        printSRFSConfiguration.pageWidth - 85,
        printSRFSConfiguration.pageHeight -
        footerConfig.footerimgHeight,
        "left",
        printSRFSConfiguration.fontSizes.h2
      );
    }

    printSRFSConfiguration.pdfDoc.save(`${srfDetails?.companyName}_${srfDetails?.serviceReqNumber}.pdf`);
    setDownlaodPdfLoading(false);
  } catch (error) {
    console.error("Error generating PDF:", error);
    setDownlaodPdfLoading(false);
  }
};
