import { headerConfig } from "./srfsConfig";

export const printSRFSConfiguration = {
  pdfDoc: null,
  fontSize: 8,
  align: {
    left: "left",
    right: "right",
    center: "center",
  },
  fontSizes: {
    h1: 12,
    h2: 9,
    h3: 9,
    h4: 8,
    h5: 7,
    h6: 6,
    h7: 13,
    heleeven: 11,
    hten:10
  },
  textColor: "black",
  margin: 25,
  rowHeight: 20,
  pageHeight: 841,
  pageWidth: 595,
  lineHeight: 15,
  cellPadding: 5,
  cellPaddingTable:{ top: 5, left: 5, bottom: 5, right: 5 },
  lineWidth: 0.5,
  spaceConfig: {
    top: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
    Bottom: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
    locallineHeight: {
      S0: 0,
      S1: 1,
      S2: 2,
      S3: 3,
      S4: 4,
      S5: 5,
      S6: 6,
      S7: 7,
      S8: 8,
      S9: 9,
      S10: 10,
    },
  },
};

export const layoutConfig = {
  contentWidth:
    printSRFSConfiguration.pageWidth -
    2 * printSRFSConfiguration.margin,
  xPositionGlobal: printSRFSConfiguration.margin,
  yPositionGlobal:
    printSRFSConfiguration.margin +
    headerConfig.lettterImgHeight +
    headerConfig.headerTableHeight,
};
export const printSRFSConfigData = {
  SRFSDetailsConfig: {},
  instrumentList: [],
  letterHeadAndElectronicAndDraftConfig: {},
  footerSignSettingConfig: {},
  SRFSId: null,
  IsCSRF:false,
};

export const parseValue = (value) => {
  value = value || "";
  value = value.replace("_unit_", "");
  value = value.replace("#", " ");
  return value;
};
