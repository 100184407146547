
import {
  accuracyFormat,
  DateisValid,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow";

export let printCertificateSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: true,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: false,
  jrpmtable: false,
  readingTableSerialNoColumnShow: false,
};

export const headerConfig = {
  headerCALIBRATIONCERTIFICATE: true,
  headerCALIBRATIONCERTIFICATEgap: 10,
  headerCALIBRATIONCERTIFICATEwithoutgap: 7,
  lettterImgHeight: 95,
  headerTableHeight: 30,
  headerandbodygap: 10,
  tableRowfontSizes: 9,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONCERTIFICATExpostion:
    headerConfig.headerCALIBRATIONCERTIFICATE
      ? headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEgap
      : headerConfig.lettterImgHeight +
        headerConfig.headerCALIBRATIONCERTIFICATEwithoutgap,
  headerCALIBRATIONCERTIFICATExpostionafter:
    headerConfig.lettterImgHeight +
    headerConfig.headerCALIBRATIONCERTIFICATEgap +
    10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONCERTIFICATE
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  electronicSign:true,
  footerCALIBRATIONCERTIFICATE: false,
  footerimgRemove:true,
  footerlinewidth: 0,
  footerimgHeight: 70,
  footertableHeight: 50,
  footerandbodygap: 57,
  endcertificateandimggap: 5,
  stampui: true,
};
let pageHeight = 841;
export const certificateBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    6,
  // height: 570
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 300,
  logoHeight: 400,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

export const digitallySignConfig = {
  digitallySignONorOFF: false,
  signatureTextContnet: [
    `REVA PHOENIX LABS AND CONSULTANTS`,
    `PRIVATE LIMITED`,
    `Date:  ${new Date()
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .replace(",", "")} +05:30`,
    `Reason: Calibration Certificate`,
    `Location: Chennai`,
  ],
};



export let certificateEndSection = [
  {
    type: "header",
    align: "center",
    content: "TERMS & CONDITIONS",
  },
  {
    type: "table",
    index: true,
    content: [
      "This Calibration Certificate is issued in accordance with terms and conditions of accreditation laid down by National Accredatation Board for Testing and Calibration Laboratories (NABL), India,Which assesses the Measurement Capability of Laboratories and its traceability to recognized National Standards and to the Units of Measurement realized at the corresponding National Standards Laboratory.",
      "The Calibration masters used are maintained in accordance with ISO/IEC 17025:2017 and are traceable to National and International Standards.","The reports shall not be reproduced except in full, unless the managements representative of Precise Testing and Calibration Centre Pvt. Ltd. has given approval in writing in accoradance with ISO/lEC 17025 : 2017.","This certificate refers only to the particiular item submitted for calibration.","The results reported in the calibration certificate is valid at the time of under stated condition of measurements of the unit under Calibration Error if any, in this Certificate shall be brought to notice within 45 days from the date of this Certificate.","This Certificate is issued based on customer requirements for scientific and industrial purposes and not meant for any legal purpose & shall not be produced in the court of law.","UUC(Unit Under Calibration) readings are reported as average of 3 repeatability readings.","The reported expended uncertainity in measurements is stated as the standard uncertainity in measurements multiplied by the coverage factor K=2 which is normal distribution corresponds to a probability of approximately 95.45%.","Calibration due date mentioned as per customer request."
    ],
  },]

// Define a function to generate the textList array
export const certificateFirstSectionHeading = (
  disciplineName,
  certificateDetails
) => {
  return [
    { text: disciplineName || "", fontSize: 17 },
    { text: "Calibration Certificate", fontSize: 17 },
    { text: "For", fontSize: 17 },
    {
      text: certificateDetails?.requestedname
        ? certificateDetails?.requestedname
        : certificateDetails?.instrumentName || "",
      fontSize: 17,
    },
  ];
};

export let certificatefirstSection = [];

// Exporting MasterEQPDetails
export const MasterEQPDetails = (standards) => ({
  heading: "Master Equipment used",
  headingFontSize: 9,
  headingBottomSpace: 0,
  headingTopSpace: 0,
  headingAlign: "center",
  spacebetweenHeadingandTable: 5,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
  tableTextfontSize: 8.5,
  tableValueAlign: "center",
  keys: [
    "Sl. No.",
    "Name",
    "Make / Model",
    "Serial No.",
    "Certificate No",
    "Validity",
  ],
  values: standards.map((item, index) => [
    index + 1,
    item.standardName,
    item.make && item.model  ? `${item.make} / ${item.model}` : `${item.make} ${item.model}`,
    item.serialNo,
    item.certificateNumber,
    DateisValid(item.validUpto),
  ]),
    // `${item.certificateNumber} Valid till ${DateisValid(item.validUpto)}`,
});

export const UUCDetails = (certificateDetails) => {
  let ISendcalibrationDate  = certificateDetails?.endCalibrationDate ?  `${DateisValid(certificateDetails?.calibrationDate)} to ${DateisValid(certificateDetails?.endCalibrationDate)}` : DateisValid(certificateDetails?.calibrationDate);
  
  const getExtraColumn = (key) => {
    const value = certificateDetails?.extraColumnsParse?.[key];
    return value ? { key, value } : null; // Only return key-value pair if there's a value
  };

  const standardFields = [
    {
      key: "Name of Instrument",
      value:
        certificateDetails?.requestedname || certificateDetails?.instrumentName,
    },
    {
      key: "Calibrated At",
      value: certificateDetails?.location == 1 ? "In House" : "Onsite",
    },
    { key: "Make", value: certificateDetails?.make },
    { key: "Condition on Receipt", value: certificateDetails?.ConOfDuc },
    { key: "Model", value: certificateDetails?.model },
    // { key: "Date of Receipt", value:  DateisValid(certificateDetails?.receiptDate) },
    { key: "Serial No", value: certificateDetails?.serialNumber },
    { key: "Code/ID Number", value: certificateDetails?.DUCID },
    { key: "Date of Calibration", value: ISendcalibrationDate },
    { key: "Range", value: parseRange(certificateDetails?.ranges) },
    { key: "Calibration Due On", value:  certificateDetails?.calFrequency == "Not Required"
      ? "-"
      : certificateDetails?.calFrequency == "Customer Defined" 
      ? "Customer Defined" 
      : DateisValid(certificateDetails?.nextDueDate)
     },
      // { key: "SRF No", value: certificateDetails?.certificatesserviceReqNumber },
      {
        key: "LC/Resolution",
        value: parseRange(certificateDetails?.lc),
      },
    // { key: "Date of Issue", value: certificateDetails?.dateOfIssue
    //   ? DateisValid(certificateDetails?.dateOfIssue)
    //   : DateisValid(certificateDetails?.approvedDate)
    // },
    { key: "Location", value: certificateDetails?.locationOfInstrument },
    { key: "Accuracy", value: accuracyFormat(certificateDetails?.accuracy) },
    { key: "Operating Range", value: parseRange(certificateDetails?.operatingRange) },
    // {
    //   key: "Calibration Procedure",
    //   value: certificateDetails?.calProcRefNo
    //     ? certificateDetails?.calProcRefNo
    //     : certificateDetails?.calibrationProcedureNo,
    // },
    // {
    //   key: "Standard Reference",
    //   value: certificateDetails?.isaStandard
    //     ? certificateDetails?.isaStandard
    //     : certificateDetails?.referenceStandards,
    // },
  ];

  // Extra fields
  const extraFields = ["Part No", "Type "];

  // Add extra fields only if they have a value
  const extraPanelFields = extraFields
    .map(getExtraColumn)
    .filter((item) => item !== null);
  // Filter standard fields to include only those with values
  const filteredStandardFields = standardFields.filter((field) => field.value);
  // Combine filtered standard fields with extra fields (no special ordering)
  const allFields = [...filteredStandardFields, ...extraPanelFields];
  // Create rows from the fields, pairing key-value pairs
  const rows = [];
  for (let i = 0; i < allFields.length; i += 2) {
    rows.push([allFields[i], allFields[i + 1] || null]); // Add extra field as null if there's no pair
  }

  return {
    heading: "DETAILS OF UNIT UNDER CALIBRATION (UUC)",
    headingFontSize: 9,
    headingBottomSpace: 0,
    headingTopSpace: 0,
    headingAlign: "center",
    spacebetweenHeadingandTable: 5,
    innerBorder: 0,
    cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
    tableTextfontSize: 8.5,
    middleColumn: true,
    rows,
  };
};

export const NameAndAddressDetails = (certificateDetails,clientInfo) => {
  let ISendcalibrationDate  = certificateDetails?.endCalibrationDate ?  `${DateisValid(certificateDetails?.calibrationDate)} to ${DateisValid(certificateDetails?.endCalibrationDate)}` : DateisValid(certificateDetails?.calibrationDate);

  return {
    leftPanel: {
      keys: ["Customer Name & Address"],
      values: [
        `${clientInfo?.clientName || ""}\n${
          clientInfo?.newAddressArray?.[0]?.address || ""
        }`,
      ],
    },
    rightPanel: {
      keys: ["Customer Reference", "Date of Receipt","Date of Issue"
        // "Date Calibration", "Due Date", "Date Issue"
      ],
      values: [
        certificateDetails?.dcNumber || "",
        DateisValid(certificateDetails?.receiptDate),
        // DateisValid(certificateDetails?.calibrationDate),
        // certificateDetails?.calFrequency == "Not Required"
        //   ? "-"
        //   : DateisValid(certificateDetails?.nextDueDate),
        certificateDetails?.dateOfIssue
          ? DateisValid(certificateDetails?.dateOfIssue)
          : DateisValid(certificateDetails?.approvedDate),
      ],
    },
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
    tableTextfontSize: 8.5,
    firsttwocolumnrowSpan: false
  };
};

export const performanceClaibrationDetails = (certificateDetails) => ({
  leftPanel: {
    keys: ["Date of Performance of Calibration"],
    values: [DateisValid(certificateDetails?.calibrationDate)],
  },
  rightPanel: {
    keys: ["Date of Issue", "Recommended Due Date"],
    values: [
      certificateDetails?.dateOfIssue
        ? DateisValid(certificateDetails?.dateOfIssue)
        : DateisValid(certificateDetails?.approvedDate),
        certificateDetails?.calFrequency == "Not Required" ? "-" : certificateDetails?.calFrequency == "Customer Defined" ? "Customer Defined" : DateisValid(certificateDetails?.nextDueDate),
    ],
  },
});

export const dynamicHeader = (certificateDetailsConfig, calType) => {
  const headerKeysAndValues = calType != 1
  ? [{ keys: "Certificate Number", values: certificateDetailsConfig?.certificateNumber }]
  : [
      { keys: "ULR Number", values: certificateDetailsConfig?.ULRNo?.replace("\n", "") },
      { keys: "Certificate Number", values: certificateDetailsConfig?.certificateNumber },
    ];

    
    const leftAndRightKeyvalue = headerKeysAndValues.filter((field) => field.values);
    const rows = [];
    for (let i = 0; i < leftAndRightKeyvalue.length; i += 2) {
      rows.push([leftAndRightKeyvalue[i], leftAndRightKeyvalue[i + 1] || null]);
    }

  return {
    mergeKeyValue:true,
    headerKeysAndValues,
    firstcolheadersecondcolvalue:false,
    firstrowHeaderscondValue:false,   
    rows
  };
};


