import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToWords } from "to-words";
import moment from "moment";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";

let config = {
  margin: 15,
  borderMagin: 7,
  headerHeight: 60,
  footerHeight: 60,
  fontSize: 9,
  bordergap:5,
  currentY:0,
};

function generateHeaderSection(
  doc,
  invoiceAllData = {},
  newAddressArray = [],
  invoiceData = {},
  printTitle,
  formatType,
  clientInfo
) {
  const invoiceTitle = printTitle ? "Tax Invoice" : "Proforma Invoice";
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 102);
  doc.text(
    invoiceTitle,
    doc.internal.pageSize.getWidth() / 2,
    config?.headerHeight + config?.margin + 5 + config.bordergap,
    {
      align: "center",
    }
  );
  if (formatType) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(config.fontSize);
    doc.setTextColor(0, 0, 0);
    const textWidth = doc.getTextWidth(formatType);
    doc.text(
      formatType,
      doc.internal.pageSize.getWidth() - textWidth - 10,
      config?.headerHeight + config?.margin + 7
    );
  }

  const startYPos = config?.headerHeight + 12 + config.bordergap + config?.margin;
  const row1 = [
    {
      content: "Invoice No",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    { content: invoiceAllData?.invoiceNumber || "" },
    {
      content: "Invoice Date",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    {
      content: invoiceAllData?.invoiceDate
        ? moment(invoiceAllData?.invoiceDate).format("DD/MM/YYYY")
        : "",
    },
  ];
  const toBlock = ["To, \n", invoiceData?.client_companyName || ""];
  if (newAddressArray?.[0]?.address) {
    toBlock.push("\nBilling Address :");
    toBlock.push(newAddressArray[0].address);
  }
  if (newAddressArray?.[0]?.shipping?.address) {
    toBlock.push("\nShipping Address :");
    toBlock.push(newAddressArray[0].shipping.address);
  }
  const customerChallanLabels = [
    "Customer Challan No.",
    "Customer Challan Date.",
    "Our Challan No.",
    "Our Challan Date.",
    "P.O. No.",
    "P.O. Date.",
  ].join("\n \n");
  const customerChallanValues = [
    invoiceData?.invoice_challanNo || "",
    invoiceData?.invoice_challanDate
      ? moment(invoiceData?.invoice_challanDate).format("DD/MM/YYYY")
      : "",
    invoiceData?.invoice_ourChallanNo || "",
    invoiceData?.invoice_ourChallanDate
      ? moment(invoiceData?.invoice_ourChallanDate).format("DD/MM/YYYY")
      : "",
    invoiceData?.invoice_poNumber || "",
    invoiceData?.invoice_poDate
      ? moment(invoiceData?.invoice_poDate).format("DD/MM/YYYY")
      : "",
  ].join("\n \n");
  const row2 = [
    {
      content: toBlock.join("\n"),
      colSpan: 2,
      styles: { halign: "left", fontStyle: "bold" },
    },
    {
      content: customerChallanLabels,
      styles: { fontStyle: "bold", halign: "left",  },
    },
    {
      content: customerChallanValues,
      styles: {},
    },
  ];
  const row3 = [
    {
      content: "GST NO",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    { content: clientInfo?.gstNumber || "" },
    {
      content: "Reference Number",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    { content: invoiceAllData?.referenceNumber || "" },
  ];
  const row4 = [
    {
      content: "Kind Attn",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    {
      content: clientInfo?.contact?.contactPersonName || "",
    },
    {
      content: "Payment",
      styles: { fontStyle: "bold", halign: "left",  },
    },
    { content: "100% Against Delivery" },
  ];
  const tableBody = [row1, row2, row3, row4];
    // Calculate the total available width of the table
  const totalPageWidth = doc.internal.pageSize.getWidth() - config.margin * 2;
  const columnPercentages = [20, 30, 25, 25];
  const columnWidths = columnPercentages.map((percentage) => {
      return (totalPageWidth * percentage) / 100;
    });
  doc.autoTable({
    startY: startYPos,
    body: tableBody,
    theme: "grid",
    styles: {
      fontSize: config.fontSize,
      textColor: 0,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      valign: "middle",
      cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
    },
    margin: { left: config.margin, right: config.margin },
    headStyles: { fillColor: [217, 217, 217] },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY; 
}

function calculateAmounts(productDetails, productList, invoiceAllData) {
  const amounts = {
    totalAmountBeforeTax: 0,
    totalAmountBeforeDiscount: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: parseFloat(invoiceAllData?.conveyanceCharges) || 0,
    courierCharges: parseFloat(invoiceAllData?.courierCharges) || 0,
    discount: parseFloat(invoiceAllData?.discount) || 0,
    grandTotal: 0,
  };

  amounts.totalAmountBeforeTax = productDetails.reduce((sum, product) => {
    const foundProduct = productList.find(
      ({ id }) => id === product.equipmentId
    );
    product.name = foundProduct?.instrumentName || "";
    const totalAmount = product.rate * product.quantity;
    const discountAmount =
      (totalAmount * (parseFloat(product.discount) || 0)) / 100;
    product.amount = totalAmount - discountAmount;
    return sum + product.amount;
  }, 0);

  amounts.totalAmountBeforeTax +=
    amounts.conveyanceCharges + amounts.courierCharges;
  amounts.totalAmountBeforeDiscount = amounts.totalAmountBeforeTax;
  const discountAmount =
    (amounts.totalAmountBeforeTax * amounts.discount) / 100;
  amounts.totalAmountBeforeTax -= discountAmount;
  const gstPercentage = parseFloat(invoiceAllData?.gstpercentages) || 18;
  if (invoiceAllData?.gstType === 1) {
    amounts.sgst = amounts.cgst =
      (amounts.totalAmountBeforeTax * gstPercentage) / 200;
    amounts.gst = amounts.sgst + amounts.cgst;
  } else if (invoiceAllData?.gstType === 2) {
    amounts.gst = (amounts.totalAmountBeforeTax * gstPercentage) / 100;
  }
  amounts.grandTotal = amounts.totalAmountBeforeTax + amounts.gst;
  return amounts;
}

function generateTableWithSummary(
  doc,
  productDetails,
  productList,
  invoiceAllData,
  margin,
  drawMarginRect,
  letterHead
) {
  const toWords = new ToWords();
  const hasDiscount = productDetails.some(
    (product) => product?.discount != null
  );
  const amounts = calculateAmounts(productDetails, productList, invoiceAllData);
  const columns = [
    "Sr.No.",
    "Description of Item",
    "Description",
    "SAC | HSN Code",
    "Type of Service",
    "Quantity",
    "Rate",
    ...(hasDiscount ? ["Discount"] : []),
    "Amount",
  ];
  const rows = productDetails.map((product, i) => [
    i + 1,
    product.requestedName || product.name,
    (product.rangeValue?.replace(/#/g, " ").replace(/\|/g, " to ")?.trim() ||
      "") +
      " " +
      (product.description || ""),
    product.hsnCode || "",
    product.service || "",
    product.quantity || 1,
    (product.rate || 0).toFixed(2),
    ...(hasDiscount ? [`${product.discount || 0} %`] : []),
    (product.amount || 0).toFixed(2),
  ]);
  const footRows = [];
  if (amounts.conveyanceCharges > 0) {
    footRows.push([
      {
        content: "Onsite Charges",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.conveyanceCharges.toFixed(2),
        styles: { halign: "right" },
      },
    ]);
  }
  if (amounts.courierCharges > 0) {
    footRows.push([
      {
        content: "Courier Charges",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.courierCharges.toFixed(2),
        styles: { halign: "right" },
      },
    ]);
  }
  footRows.push([
    {
      content: "Total Amount",
      colSpan: columns.length - 1,
      styles: { halign: "right", fontStyle: "bold" },
    },
    {
      content: amounts.totalAmountBeforeDiscount.toFixed(2),
      styles: { halign: "right" },
    },
  ]);
  if (amounts.discount) {
    footRows.push([
      {
        content: `Discount`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: `${amounts.discount} %`,
        styles: { halign: "right" },
      },
    ]);
    footRows.push([
      {
        content: "Total Amount After Discount",
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      {
        content: amounts.totalAmountBeforeTax.toFixed(2),
        styles: { halign: "right", fontStyle: "bold" },
      },
    ]);
  }
  if (invoiceAllData?.gstType === 1) {
    footRows.push([
      {
        content: `CGST (${(invoiceAllData?.gstpercentages || 18) / 2}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: (amounts.gst / 2).toFixed(2), styles: { halign: "right" } },
    ]);
    footRows.push([
      {
        content: `SGST (${(invoiceAllData?.gstpercentages || 18) / 2}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: (amounts.gst / 2).toFixed(2), styles: { halign: "right" } },
    ]);
  } else if (invoiceAllData?.gstType === 2) {
    footRows.push([
      {
        content: `IGST (${invoiceAllData?.gstpercentages || 18}%)`,
        colSpan: columns.length - 1,
        styles: { halign: "right", fontStyle: "bold" },
      },
      { content: amounts.gst.toFixed(2), styles: { halign: "right" } },
    ]);
  }
  footRows.push([
    {
      content: "Grand Total Amount",
      colSpan: columns.length - 1,
      styles: { halign: "right", fontStyle: "bold" },
    },
    { content: amounts.grandTotal.toFixed(2), styles: { halign: "right" } },
  ]);
  footRows.push([
    {
      content: `Amount in Words : ${toWords.convert(
        amounts.grandTotal ? amounts.grandTotal.toFixed(2) : 0,
        { currency: true }
      )}`,
      colSpan: columns.length,
      styles: { halign: "left", fontStyle: "bold" },
    },
  ]);

  const allRows = [...rows, ...footRows];

  const startY = doc.lastAutoTable.finalY;

  doc.autoTable({
    margin: {
      top: margin + config?.headerHeight + 2,
      right: margin,
      bottom: margin + config?.footerHeight,
      left: margin,
    },
    head: [columns],
    body: allRows,
    // foot: footRows,
    startY: startY,
    theme: "grid",
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
      textColor: 0,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [217, 217, 217],
      textColor: 0,
      halign: "center",
      valign: "middle",
    },
    bodyStyles: { halign: "center", valign: "middle" },
    footStyles: { fillColor: [255, 255, 255], textColor: 0, lineWidth: 0.1 },
    didDrawPage: function () {
      drawMarginRect(doc);
      // Add header and footer images on each page if letterHead is true
      if (letterHead) {
        addHeaderFooter(doc);
      }
    },
  });

  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY; 
}

// Helper function to draw the header and footer images
function addHeaderFooter(doc) {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Draw header image at the top (x:0, y:0)
  doc.addImage(UnitechHeader, "PNG", 0, 0, pageWidth, config?.headerHeight);
  doc.addImage(
    UnitechFooter,
    "PNG",
    0,
    pageHeight - config?.footerHeight,
    pageWidth,
    config?.footerHeight
  );
}

const generateBankDetailsTable = (
  doc,
  GST_NUMBER,
  BANK_DETAILS,
  finalY,
  margin,
  totalWidth
) => {
  const tableData = [
    [
      {
        content:
          "Bank Details For Payment -\n" +
          `${BANK_DETAILS["Bank Name"]}\n` +
          `Bank A/c. No.: ${BANK_DETAILS["Bank A/c. No."]}\n` +
          `IFSC Code: ${BANK_DETAILS["IFSC Code"]}\n` +
          `GST NO: ${GST_NUMBER}`,
        styles: {
          fontStyle: "normal",
          halign: "left",
          cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
          lineHeight: 1.5,
        },
      },
      {
        content: "For BFI, Mumbai\n\n\n\nAuthorized Signatory",
        styles: {
          fontStyle: "bold",
          halign: "center",
          cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
        },
      },
    ],
  ];

  doc.autoTable({
    margin: { top: margin, right: margin, bottom: margin, left: margin },
    startY: finalY + 2,
    body: tableData,
    theme: "plain",
    styles: {
      fontSize: config.fontSize,
      cellPadding: { top: 5, left: 5, bottom: 5, right: 5 },
      textColor: 0,
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    columnStyles: {
      0: {
        cellWidth: (totalWidth - margin * 2) * 0.6,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      1: {
        cellWidth: (totalWidth - margin * 2) * 0.4,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
    },
  });

  const newY = doc.lastAutoTable.finalY;
  config.currentY = newY; 

  return doc.lastAutoTable.finalY;
};

export function generatePDF1({
  productDetails,
  productList,
  invoiceAllData,
  newAddressArray,
  invoiceData,
  GST_NUMBER,
  BANK_DETAILS,
  printTitle,
  letterHead,
  formatType,
  clientInfo,
}) {
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });
  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();

  const drawMarginRect = (doc) => {
    doc.setDrawColor(0);
    doc.setLineWidth(0.5);
    doc.rect(
      config?.borderMagin,
      config?.borderMagin + config?.headerHeight + config.bordergap,
      totalWidth - config?.borderMagin * 2,
      totalHeight -
        config?.borderMagin * 2 -
        (config?.headerHeight + config?.footerHeight) - config.bordergap
    );
  };

  // Generate the content
  generateHeaderSection(
    doc,
    invoiceAllData,
    newAddressArray,
    invoiceData,
    printTitle,
    formatType,
    clientInfo
  );
  generateTableWithSummary(
    doc,
    productDetails,
    productList,
    invoiceAllData,
    config?.margin,
    drawMarginRect,
    letterHead
  );
  let finalY = doc.lastAutoTable ? doc.lastAutoTable.finalY : 60;

  const remainingHeight =
    totalHeight - config.currentY - config?.margin - config?.footerHeight - config?.borderMagin;

  const termsHeight = 50;

  if (remainingHeight >= termsHeight) {
    // If enough space is available on the same page
    generateBankDetailsTable(
      doc,
      GST_NUMBER,
      BANK_DETAILS,
      config.currentY,
      config?.margin,
      totalWidth
    );
  } else {
    // If not enough space, create a new page
    doc.addPage();
    generateBankDetailsTable(
      doc,
      GST_NUMBER,
      BANK_DETAILS,
      config?.borderMagin + config?.headerHeight + 5 + config.bordergap,
      config?.margin,
      totalWidth
    );
  }

 
  const totalPages = doc.getNumberOfPages();
  for (let page = 1; page <= totalPages; page++) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(config.fontSize);
    doc.setTextColor(0);
    doc.setPage(page);
    let content = `Page No : ${String(page).padStart(2, "0")} of ${String(
      totalPages
    ).padStart(2, "0")}`;
    doc.text(
      content,
      doc.internal.pageSize.getWidth() - config.margin  - 70,
      config?.headerHeight + config?.margin - 7,
      { align: "left" }
    );
  }

  // In case there are pages that didn't trigger didDrawPage, loop through all pages to add header/footer
  if (letterHead) {
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addHeaderFooter(doc);
      drawMarginRect(doc);
    }
  }

  doc.save( `${invoiceData?.client_companyName}_${invoiceAllData?.invoiceNumber}.pdf`);
}
